const Config = {
    CLIENT_ID: '"simple-system"',
    CLIENT_NAME: '"管理系统"',
    TOKEN_KEY: 'cloud-token',
    PRINCIPAL_KEY: 'principal',
    TIMEOUT: 1200000,
    tokenCookieExpires: 1,
    BASE_API: '',
}


export default Config
