import fecha from 'element-ui/src/utils/date'
import ArrayUtil from './array'

/**
 * 日期工具类
 */
const DateUtil = {}

/**
 * 日期格式化
 * @param time
 * @param format
 * @returns {string}
 */
DateUtil.format = function(time, format = 'yyyy-MM-dd hh:mm:ss') {
  if (time) {
    time = DateUtil.fixiOS(time)
    const datetime = new Date(time)
    const date = {
      'M+': datetime.getMonth() + 1,
      'd+': datetime.getDate(),
      'h+': datetime.getHours(),
      'm+': datetime.getMinutes(),
      's+': datetime.getSeconds(),
      'q+': Math.floor((datetime.getMonth() + 3) / 3),
      'S+': datetime.getMilliseconds()
    }
    if (/(y+)/i.test(format)) {
      format = format.replace(RegExp.$1, (datetime.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in date) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? date[k] : ('00' + date[k]).substr(('' + date[k]).length)
        )
      }
    }
    return format
  } else {
    return ''
  }
}

/**
 * 获取当前日期
 * @returns {string}
 */
DateUtil.getNowDate = function(format = 'yyyy-MM-dd') {
  return DateUtil.format(new Date(), format)
}

/**
 * 获取当前时间
 * @returns {string}
 */
DateUtil.getNowTime = function(format = 'yyyy-MM-dd hh:mm:ss') {
  return DateUtil.format(new Date(), format)
}

/**
 * 获取当前月份
 * @returns {string}
 */
DateUtil.getNowMonth = function() {
  return DateUtil.format(new Date(), 'yyyy-MM')
}
/**
 * 获取当前年份
 * @returns {string}
 */
DateUtil.getNowYear = function() {
  return DateUtil.format(new Date(), 'yyyy')
}
/**
 * 获取当前周一
 * @param now
 * @returns {string}
 */
DateUtil.getNowMonday = function(now) {
  if (!now) {
    now = new Date()
  }
  now.setDate(now.getDate() - now.getDay() + 1)
  return DateUtil.format(now, 'yyyy-MM-dd')
}

/**
 * 解析日期
 * @param time
 * @param format
 * @returns {Date|boolean}
 */
DateUtil.parse = function(time, format = 'yyyy-MM-dd hh:mm:ss') {
  if (time) {
    if (typeof time === 'string') {
      time = DateUtil.fixT(time)
      return fecha.parse(time + ' 00:00:00', format)
    } else {
      // 时间戳
      return new Date(time)
    }
  }
  return null
}

/**
 * 日期加减
 * @param date
 * @param num
 * @returns {string}
 */
DateUtil.addDate = function(date, num) {
  if (date instanceof Date === false) {
    date = DateUtil.parse(date, 'yyyy-MM-dd')
  }
  date.setDate(date.getDate() + num)
  return DateUtil.format(date, 'yyyy-MM-dd')
}

/**
 * 获取开始时间
 * @param range
 * @returns {*}
 */
DateUtil.getBeginTime = function(range) {
  return ArrayUtil.getItem(this.createTimeRange, 0)
}

/**
 * 获取截止时间
 * @param range
 * @returns {string|null|*}
 */
DateUtil.getEndTime = function(range) {
  const item = ArrayUtil.getItem(this.createTimeRange, 1)
  if (item == null) {
    return null
  }
  if (item.endsWith(' 23:59:59')) {
    return item
  }
  return item + ' 23:59:59'
}

/**
 * 修复iOS系统无法识别LocalDateTime的问题
 * @param time
 * @returns {string}
 */
DateUtil.fixiOS = function(time) {
  if (time && typeof time === 'string') {
    const userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.indexOf('macintosh') > -1 && typeof time === 'string') {
      if (time.length >= 19) {
        time = time.substr(0, 19)
      } else if (time.length >= 9) {
        time = time.substr(0, 9)
      }
      time = time.replace(/-/g, '/')
    }
    time = time.replace('T', ' ')
  }
  return time
}

/**
 *  去除T
 * @param time
 * @returns {*}
 */
DateUtil.fixT = function(time) {
  if (time && typeof time === 'string') {
    time = time.replace('T', ' ')
  }
  return time
}

/**
 * 获取最近几天的时间数组
 * @param num 周数，默认最近一天
 * @returns {[Date, Date]}
 */
DateUtil.getLastDays = function(num = 1) {
  const end = new Date()
  const start = new Date()
  start.setTime(start.getTime() - 3600 * 1000 * 24 * num)
  end.setHours(23, 59, 59)
  start.setHours(0, 0, 0)
  return [start, end]
}

/**
 * 获取最近几天的时间数组
 * @param num 周数，默认最近一天
 * @returns {[Date, Date]}
 */
DateUtil.getFutureDays = function(num = 1) {
  const end = new Date()
  const start = new Date()
  end.setTime(start.getTime() + 3600 * 1000 * 24 * num)
  end.setHours(23, 59, 59)
  start.setHours(0, 0, 0)
  return [start, end]
}

/**
 * 获取 几分钟前，几小时前等等
 * @param time
 */
DateUtil.getLastLabel = function(time) {}

export default DateUtil
