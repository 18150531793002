import Config from "@/config";

/**
 * HOST工具类
 */
const HostUtil = {}

/**
 * 获取根域名
 * @returns {string}
 */
HostUtil.getRootDomain = function () {
    let domain = window.location.hostname
    let hostname
    if (domain.endsWith('.com.cn')) {
        hostname = domain.match(/\.(\w+\.com\.cn)$/)
    } else if (domain.endsWith('.com')) {
        hostname = domain.match(/\.(\w+\.com)$/)
    } else {
        hostname = domain.match(/\.(\w+\.cn)$/)
    }
    if (hostname && hostname.length > 1) {
        domain = hostname[1]
    }
    console.log(domain)
    return domain
}

/**
 * 获取服务地址
 * @returns {string}
 */
HostUtil.getServerUrl = function () {
    // 配置地址
    const baseApi = Config.BASE_API
    // 访问地址
    const origin = window.location.origin
    // 最终访问地址
    let api = ''
    if (baseApi === 'proxy') {
        api = origin + '/proxy'
    } else if (baseApi === '') {
        // 访问端口
        const port = window.location.port
        // 没有端口，说明是通过域名访问
        if (!port) {
            api = origin + ':18086'
        } else if (port === '38086') {
            // 政务外网
            api = origin.replace(':38086', ':18086')
        } else {
            // 默认端口
            api = origin.replace(':808', ':1808')
        }
    } else {
        api = baseApi
    }
    return api
}

/**
 * 获取管理端地址
 * @returns {string}
 */
HostUtil.getAdminUrl = function (path) {
    if (path) {
        return window.location.origin + '/zero' + path
    }
    return window.location.origin + '/zero'
}


/**
 * 跳转到管理端地址
 * @param path
 */
HostUtil.gotoAdmin = function (path) {
    window.location.href = HostUtil.getAdminUrl() + path
}

export default HostUtil
