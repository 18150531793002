import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRouterMap = [
    {
        path: '/',
        component: () => import('@/views/index'),
    },
    {
        path: '/case',
        component: () => import('@/views/case/index'),
    },
    {
        path: '/case/detail',
        component: () => import('@/views/case/detail'),
    },
    {
        path: '/register',
        component: () => import('@/views/register'),
    },
    {
        path: '/price',
        component: () => import('@/views/price/index'),
    },
    {
        path: '/join',
        component: () => import('@/views/join/index'),
    },
]

export default new Router({
    mode: 'history',
    scrollBehavior: () => ({y: 0}),
    routes: constantRouterMap
})
