<template>
  <div>
    <el-checkbox
      :indeterminate="isIndeterminate()"
      :value="isCheckAll()"
      @change="handleCheckAllChange"
      :disabled="disabled"
      v-if="checkAllOption"
    >
      {{ checkAllLabel }}
    </el-checkbox>
    <div style="margin: 10px 0;" v-if="checkAllOption"></div>
    <el-checkbox-group v-model="checkList" :disabled="disabled" @change="handleChange">
      <el-checkbox v-for="item in options" :label="item.value" :key="item.value">
        {{ item.label }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
import { getCache } from '@/utils/cache'
import ArrayUtil from '../../utils/array'

export default {
  name: 'DictCheck',
  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean
    },
    // 是否有全选选项
    checkAllOption: {
      type: Boolean,
      default: false
    },
    // 全选的名称
    checkAllLabel: {
      type: String,
      default: '全选'
    },
    // 包含选项
    includes: {
      type: String,
      required: false,
      default: ''
    },
    // 排除选项
    excludes: {
      type: String,
      required: false,
      default: ''
    }
  },
  watch: {
    type: function(val) {
      this.initData()
    },
    value: function(val) {
      // 内部更新
      if (this.innerInput) {
        this.innerInput = false
      } else {
        this.initData()
      }
    }
  },
  computed: {},
  data() {
    return {
      innerInput: false, // 内部更新
      isIndeterminate: function() {
        return this.checkList.length > 0 && this.checkList.length < this.dicts.length
      },
      isCheckAll: function() {
        return this.checkList.length > 0 && this.checkList.length === this.dicts.length
      },
      dicts: [],
      options: [],
      checkList: []
    }
  },
  created: function() {
    this.initData()
  },
  methods: {
    /**
     * 初始化数据
     */
    initData: function() {
      getCache('dict', this.type, '/sys/dict/item/select', {
        dictNo: this.type
      }).then(data => {
        this.dicts = data
        this.initOption()
        this.initCheck()
      })
    },
    /**
     * 初始化已选
     */
    initCheck() {
      this.checkList = []
      if (this.value) {
        const valueArr = ArrayUtil.split(this.value)
        for (let i = 0; i < this.dicts.length; i++) {
          if (valueArr.indexOf(this.dicts[i].value) !== -1) {
            this.checkList.push(this.dicts[i].value)
          }
        }
      }
    },
    /**
     * 加载选项
     */
    initOption() {
      let result = []
      if (this.includes) {
        // 包含选项
        const arr = this.includes.split(',')
        for (let i = 0; i < this.dicts.length; i++) {
          if (arr.indexOf(this.dicts[i].value) !== -1) {
            result.push(this.dicts[i])
          }
        }
      } else if (this.excludes) {
        // 排除选项
        const arr = this.excludes.split(',')
        for (let i = 0; i < this.dicts.length; i++) {
          if (arr.indexOf(this.dicts[i].value) === -1) {
            result.push(this.dicts[i])
          }
        }
      } else {
        result = this.dicts
      }
      this.options = result
    },
    /**
     * 选中选项
     * @param event
     */
    handleChange(event) {
      this.innerInput = true
      this.$emit('input', this.checkList.join())
      this.$emit('change', this.checkList.join())
    },
    /**
     * 全选
     */
    handleCheckAllChange(value) {
      this.checkList = []
      if (value) {
        for (let i = 0; i < this.dicts.length; i++) {
          this.checkList.push(this.dicts[i].value)
        }
      }
      this.$emit('input', this.checkList.join())
      this.$emit('change', this.checkList.join())
    }
  }
}
</script>
