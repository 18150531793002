import Vue from 'vue'
import App from './App.vue'

// ============================================================================= element-ui
import { Button, Input } from 'element-ui'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n

import './utils/directives.js'

Vue.use(ElementUI, { locale })
Vue.use(Button)
Vue.use(Input)

// ============================================================================= 全局公共组件注册
import DictSelect from './components/Dict/DictSelect'
import DictLabel from './components/Dict/DictLabel'
import DictCheck from './components/Dict/DictCheck'
import DictRadio from './components/Dict/DictRadio'
import ImageLabel from './components/image/ImageLabel'
import DateLabel from './components/Date/DateLabel'

Vue.component('dict-select', DictSelect)
Vue.component('dict-label', DictLabel)
Vue.component('dict-check', DictCheck)
Vue.component('dict-radio', DictRadio)
Vue.component('image-label', ImageLabel)
Vue.component('date-label', DateLabel)

import './styles/custom.scss' // global css

// 路由
import router from './router'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
