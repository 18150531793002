/**
 * 函数
 */
const func = {
  /**
   * 身份证号码校验
   * @param value
   * @returns {string}
   */
  idCard(value) {
    const city = {
      11: '北京',
      12: '天津',
      13: '河北',
      14: '山西',
      15: '内蒙古',
      21: '辽宁',
      22: '吉林',
      23: '黑龙江 ',
      31: '上海',
      32: '江苏',
      33: '浙江',
      34: '安徽',
      35: '福建',
      36: '江西',
      37: '山东',
      41: '河南',
      42: '湖北 ',
      43: '湖南',
      44: '广东',
      45: '广西',
      46: '海南',
      50: '重庆',
      51: '四川',
      52: '贵州',
      53: '云南',
      54: '西藏 ',
      61: '陕西',
      62: '甘肃',
      63: '青海',
      64: '宁夏',
      65: '新疆',
      83: '台湾',
      81: '香港',
      82: '澳门',
      91: '国外 '
    }
    let tip = ''

    if (
      !value ||
      !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(value)
    ) {
      tip = '身份证号码格式不正确'
    } else if (!city[value.substr(0, 2)]) {
      tip = '身份证号码地址编码不正确'
    } else {
      // 18位身份证需要验证最后一位校验位
      if (value.length === 18) {
        value = value.split('')
        // ∑(ai×Wi)(mod 11)
        // 加权因子
        const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
        // 校验位
        const parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
        let sum = 0
        let ai = 0
        let wi = 0
        for (let i = 0; i < 17; i++) {
          ai = value[i]
          wi = factor[i]
          sum += ai * wi
        }
        const last = parity[sum % 11]
        if (last + '' !== value[17]) {
          tip = '身份证号码校验位不正确'
        }
      } else {
        tip = '身份证号码格式不正确'
      }
    }
    return tip
  }
}

/**
 * 正则
 */
const regs = {
  required: ['必填', '必填', func.required],
  // 联系方式
  orgName: [
    '机构名称',
    '至少2位，中文、字母、数字的组合，以中文或字母开头',
    /^[\u4E00-\u9FA5a-zA-Z][a-zA-Z\d\u4e00-\u9fa5（）]+$/
  ],
  idCard: ['身份证', '您输入的身份证不正确', func.idCard],
  mobile: ['手机号', '您输入的手机号不正确', /^[1][3,4,5,6,7,8,9][0-9]{9}$/],
  phone: [
    '电话号码',
    '您输入的电话号码不正确',
    /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
    /^[1-9][0-9]{6,7}$/,
    /^[0][1-9]{3}-?[1-9][0-9]{6,7}$/,
    /^400[0-9]{7}$/,
    /^800[0-9]{7}$/
  ],
  email: [
    '邮箱',
    '您输入的邮箱地址不正确',
    /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/
  ],
  qq: ['QQ', '您输入的QQ号不正确', /^[1-9]([0-9]{5,11})$/],
  postCode: ['邮政编码', '您输入的邮政编码不正确', /^\d{6}$/],
  // 数值
  positiveInteger: ['正整数', '您输入的正整数不正确', /^[1-9]\d*$/],
  negativeInteger: ['负整数', '您输入的负整数不正确', /^-[1-9]\d*$/],
  positiveFloat: ['正浮点数', '您输入的正浮点数不正确', /^[1-9]\d*.\d*|0.\d*[1-9]\d*$/],
  negativeFloat: ['负浮点数', '您输入的负浮点数不正确', /^-[1-9]\d*.\d*|0.\d*[1-9]\d*$/],
  // IP、URL
  ip: [
    'IP',
    '您输入的IP不正确',
    /(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)\.(25[0-5]|2[0-4]\d|[0-1]\d{2}|[1-9]?\d)/
  ],
  url: ['URL', '您输入的Url不正确', /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/],
  // 字母数字
  letter: ['字母', '您输入的字母不正确', /^[A-Za-z]+$/],
  number: ['数字', '您输入的数字不正确', /^\d+$/],
  letterNumber: ['字母数字', '您输入的字母数字不正确', /^[A-Za-z0-9]+$/],
  chinese: ['中文', '请输入中文', /^[\u4e00-\u9fa5]*$/],
  // 金额
  price2: [
    '金额(2位小数)',
    '您输入的金额不正确！整数或者保留两位小数',
    /(^[1-9]\d*(\.\d{1,4})?$)|(^0(\.\d{1,2})?$)/
  ],
  price4: [
    '金额(4位小数)',
    '您输入的金额不正确！整数或者保留四位小数',
    /(^[1-9]\d*(\.\d{1,4})?$)|(^0(\.\d{1,4})?$)/
  ],
  // 其它
  varName: ['变量名', '您输入的变量名不正确', /^[a-zA-Z_\d]+$/],
  userName: [
    '用户名',
    '3-20位，中文、字母、数字、下划线的组合，以中文或字母开头',
    /^[\u4E00-\u9FA5a-zA-Z][\u4E00-\u9FA5a-zA-Z0-9_]{2,19}$/
  ],
  pwd: [
    '密码',
    '请输入至少八个字符，至少包含字母、数字，允许特殊字符',
    /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W_]{8,}$/
  ],
  md5: ['MD5', '请输入32位MD5字符串', /^[a-zA-Z\\d]{32}$/],
  image: ['图片', '图片格式不正确', /\.(jpg|jpeg|png|bmp|gif|webp)$/i],
  office: ['文档', '文档格式不正确', /\.(doc|docx|xls|xlsx|ppt|pptx|pdf|txt|csv)$/i],
  video: ['视频', '视频格式不正确', /\.(mp4|mkv|avi|rm|rmvb)$/i],
  zip: ['压缩包', '压缩格式不正确', /\.(zip|rar)$/i]
}

/**
 * 验证工具类
 */
const RuleUtil = {
  /**
   * 校验规则
   * @param val 值
   * @param type 类型
   * @returns {string}
   */
  validate(val, type) {
    const reg = regs[type]
    if (reg.length > 3) {
      let passItem = false
      for (let i = 2; i < reg.length; i++) {
        if (typeof reg[i] === 'function') {
          // 验证函数
          if (!reg[i](val)) {
            passItem = true
            break
          }
        } else if (reg[i].test(val)) {
          // 验证正则
          passItem = true
          break
        }
      }
      return passItem ? '' : reg[1]
    } else {
      let msg = ''
      if (typeof reg[2] === 'function') {
        // 验证函数
        msg = reg[2](val)
      } else {
        // 验证正则
        msg = reg[2].test(val) ? '' : reg[1]
      }
      // 密码新增校验规则
      if (!msg && type === 'pwd') {
        const flag = val.match(
          /(123|234|345|456|567|678|789|QWE|WER|ERT|RTY|TYU|YUI|UIO|IOP|OPA|PAS|ASD|SDF|DFG|FGH|GHJ|HJK|JKL|ZXC|XCV|CVB|VBN|BNM)/i
        )
        if (flag) {
          return '密码中不能使用连续3个及以上键位排序字符，如123，Qwe等'
        }
      }
      return msg
    }
  },
  /**
   * 获取规则列表
   * @returns
   */
  getRules() {
    return regs
  },
  /**
   * 必填
   * @param {} value
   * @returns
   */
  required(value) {
    if (value == null || typeof value === 'undefined') {
      return '不能为空'
    }
    value = (value + '').trim()
    if (value === '') {
      return '不能为空'
    }
    return ''
  }
}

export default RuleUtil
