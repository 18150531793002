import Cookies from 'js-cookie'
import Config from '@/config'
import {getRootDomain} from '@/utils'

const TokenKey =Config.TOKEN_KEY
const PrincipalKey = Config.PRINCIPAL_KEY

/**
 * 获取token
 * @returns {*}
 */
export function getToken() {
    return Cookies.get('cloud-token')
}

/**
 * 设置token
 * @param data
 * @param rememberMe
 * @returns {*}
 */
export function setToken(data, rememberMe) {
    const token = data.access_token
    const principal = data.principal
    // 获取一级域名，解决二级域名共享cookie
    const domain = getRootDomain()

    if (rememberMe) {
        Cookies.set(PrincipalKey, principal, {expires: Config.tokenCookieExpires, domain: domain})
        return Cookies.set(TokenKey, token, {expires: Config.tokenCookieExpires, domain: domain})
    } else {
        Cookies.set(PrincipalKey, principal, {domain})
        return Cookies.set(TokenKey, token, {domain})
    }
}

/**
 * 删除token
 * @returns {*}
 */
export function removeToken() {
    const domain = getRootDomain()
    Cookies.remove(PrincipalKey, {domain})
    return Cookies.remove(TokenKey, {domain})
}
