/** 数据字典下拉选择控件 @author lincl */
<template>
    <div class="dict-select">
        <el-select
                ref="elSelect"
                v-model="vModel"
                :multiple="multiple"
                :placeholder="placeholder"
                :clearable="clearable"
                :default-option="defaultOption"
                filterable
                :disabled="disabled"
                @change="handleChange"
                @blur.native="blur"
        >
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                <span style="display: block;" @click="handleOptionClick(item)">{{ item.label }}</span>
            </el-option>
        </el-select>
    </div>
</template>

<script>
import {getCache} from '@/utils/cache'
import ArrayUtil from '../../utils/array'

export default {
    name: 'DictSelect',
    props: {
        type: {
            type: String,
            required: false,
            default: null
        },
        value: {
            type: String,
            default: ''
        },
        // 默认选择第一个
        defaultOption: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        multiple: {
            type: Boolean
        },
        clearable: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean
        },
        // 包含选项
        includes: {
            type: String,
            required: false,
            default: ''
        },
        // 排除选项
        excludes: {
            type: String,
            required: false,
            default: ''
        },
        // 扩展参数
        extends: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            vModel: null,
            options: [],
            data: []
        }
    },
    watch: {
        type(val) {
            this.initData()
        },
        value(val) {
            if (val !== this.vModel) {
                this.initVModel()
            }
        },
        excludes() {
            this.initOption()
        }
    },
    created: function () {
        this.initVModel()
        this.initData()
    },
    methods: {
        initVModel() {
            if (this.multiple) {
                this.vModel = ArrayUtil.split(this.value)
            } else {
                this.vModel = this.value
            }
        },
        /**
         * 加载数据
         */
        initData() {
            if (this.type) {
                getCache('dict', this.type, '/sys/dict/item/select', {
                    dictNo: this.type
                }).then(data => {
                    this.data = data
                    this.initOption()
                })
            } else {
                this.options = []
            }
        },
        /**
         * 加载选项
         */
        initOption() {
            let result = []
            if (this.includes) {
                // 包含选项
                const arr = this.includes.split(',')
                for (let i = 0; i < this.data.length; i++) {
                    if (arr.indexOf(this.data[i].value) !== -1) {
                        result.push(this.data[i])
                    }
                }
            } else if (this.excludes) {
                // 排除选项
                const arr = this.excludes.split(',')
                for (let i = 0; i < this.data.length; i++) {
                    if (arr.indexOf(this.data[i].value) < 0) {
                        result.push(this.data[i])
                    }
                }
            } else {
                result = this.data
            }
            this.options = result
            if (this.defaultOption && !this.value) {
                if (this.options && this.options.length > 0) {
                    this.$emit('input', this.options[0].value)
                }
            }
        },
        handleChange(event) {
            if (this.multiple) {
                this.$emit('input', this.vModel ? this.vModel.join() : null)
                this.$emit('change', ArrayUtil.getItemsByVals(this.data, this.vModel, 'value'))
            } else {
                this.$emit('input', event)
                this.$emit('change', ArrayUtil.getItemByVal(this.data, this.vModel, 'value'))
            }
        },
        /**
         * 失去焦点
         */
        blur() {
            this.$refs.elSelect.blur()
        },
        handleOptionClick(item) {
            this.$emit('option-click', {data: item, extends: this.extends})
        }
    }
}
</script>

<style scoped></style>
