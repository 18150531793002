import request from '@/utils/request'
import { getRootDomain } from './index'
import Cookies from 'js-cookie'

/**
 * 设置缓存
 * @param prefix 前缀
 * @param key 键
 * @param value 值
 * @param ttl 有效期,秒数。不设置，则默认时间过期
 */
export function setCache(prefix, key, value, ttl) {
  const obj = { value: value }
  // 设置有效期，默认3个小时有效期
  if (!ttl || typeof ttl === 'undefined') {
    ttl = 60 * 60 * 60 * 3
  }
  obj['expireTime'] = new Date().getTime() + ttl * 1000
  sessionStorage.setItem(prefix + ':' + key, JSON.stringify(obj))
}

/**
 * 获取缓存值
 * @param prefix
 * @param key
 * @param url 当获取不到缓存时，从服务器拉取
 * @param ttl 有效期,秒数。不设置，则默认时间过期
 * @returns {Promise<unknown>}
 */
export function getCache(prefix, key, url, params, ttl) {
  if (params) {
    // eslint-disable-next-line no-unused-vars
    for (const k in params) {
      if (typeof params[k] === 'string') {
        params[k] = params[k].trim()
      }
    }
  }
  // 获取缓存方法
  const thisGetCache = function(prefix, key) {
    let obj = sessionStorage.getItem(prefix + ':' + key)
    // 缓存不存在
    if (!obj) {
      return null
    }
    obj = JSON.parse(obj)
    // 判断有效期
    if (obj.expireTime) {
      const now = new Date().getTime()
      // 超过有效期
      if (now > obj.expireTime) {
        sessionStorage.removeItem(prefix + ':' + key)
        return null
      }
    }
    return obj.value
  }
  return new Promise((resolve, reject) => {
    let data = thisGetCache(prefix, key)
    if (data === 'loading') {
      // console.log('等待其它请求返回')
      const timer = setInterval(function() {
        // console.log('等待')
        data = thisGetCache(prefix, key)
        if (data && data !== 'loading') {
          // console.log('缓存返回')
          clearInterval(timer)
          resolve(data)
        }
      }, 100)
    } else if (data) {
      resolve(data)
    } else if (url) {
      setCache(prefix, key, 'loading', 5)
      return request({
        url: url,
        method: 'get',
        params
      }).then(res => {
        data = res.content || res.data || res
        setCache(prefix, key, data, ttl)
        resolve(data)
      })
    } else {
      resolve(data)
    }
  })
}

/**
 * 删除缓存
 * @param prefix
 * @param key 为空时，则按前缀删除
 */
export function delCache(prefix, key) {
  if (key) {
    sessionStorage.removeItem(prefix + ':' + key)
  } else {
    // eslint-disable-next-line no-unused-vars
    for (const k in sessionStorage) {
      if (k.startsWith(prefix)) {
        sessionStorage.removeItem(k)
      }
    }
  }
}

/**
 * 删除所有缓存
 */
export function delAllCache() {
  // 删除session
  sessionStorage.clear()
  // 删除所有的cookie
  delAllCookie()
}

/**
 * 删除cookie
 */
export function delAllCookie() {
  var keys = document.cookie.match(/[^ =;]+(?==)/g)
  var rootDomain = getRootDomain()
  if (keys) {
    for (let i = 0; i < keys.length; i++) {
      Cookies.remove(keys[i], { domain: '/' })
      Cookies.remove(keys[i], { domain: document.domain })
      Cookies.remove(keys[i], { domain: rootDomain })
    }
  }
}
