import request from '@/utils/request'
import RuleUtil from '../../utils/rule'
import ArrayUtil from '../../utils/array'
import HostUtil from "@/utils/host";

/**
 * 搜索：附件
 * @param params
 */
export function queryUploadFile(params) {
    return request({
        url: '/upload/file',
        method: 'get',
        params
    })
}

/**
 * 删除附件
 * @param uploadFileIds
 */
export function delMyUploadFile(uploadFileIds) {
    return request({
        url: '/upload/file/',
        method: 'delete',
        params: {
            uploadFileIds
        }
    })
}

/**
 * 下载文件
 * @param uploadFileId 传入uploadFileId或包含uploadFileId的文件名
 */
export function download(uploadFileId) {
    if (uploadFileId.endsWith('@undefined')) {
        uploadFileId = uploadFileId.split('@')[0]
    }
    window.open(HostUtil.getServerUrl() + '/upload/file/download?uploadFileId=' + uploadFileId)
}

/**
 * 下载文件
 * @param uploadFileIds 传入uploadFileId或包含uploadFileId的文件名，以逗号分隔
 * @param zipName 压缩名，可为空
 */
export function downloadBatch(uploadFileIds, zipName = '') {
    const data = [
        {
            dirName: '',
            uploadFileIds: uploadFileIds
        }
    ]
    downloadBatchDir(data, zipName)
}

/**
 * 分目录下载文件
 * @param data [{dirName:'',uploadFileIds:',,,'}...]
 * @param zipName 压缩名，可为空
 */
export function downloadBatchDir(data, zipName) {
    // 处理成数组
    for (let i = 0; i < data.length; i++) {
        const item = data[i]
        if (typeof item.uploadFileIds === 'string') {
            item.uploadFileIds = ArrayUtil.split(item.uploadFileIds)
        }
    }
    request({
        url: '/upload/file/download/batch',
        method: 'post',
        params: {
            zipName
        },
        data
    }).then(res => {
        download(res.data)
    })
}

/**
 * 获取上传文件的查看url
 * @param uploadFileId 传入uploadFileId或包含uploadFileId的文件名
 * @param defaultImg 默认图片
 * @param style 图片样式
 * @returns {string}
 */
export function getViewUrl(uploadFileId, defaultImg = '', style = '') {
    return (
        HostUtil.getServerUrl() +
        '/upload/file/view?uploadFileId=' +
        uploadFileId +
        (defaultImg ? '&defaultImg=' + defaultImg : '') +
        (style ? '&style=' + style : '')
    )
}

/**
 * 获取名称
 * @param uploadFileId
 * @returns {*}
 */
export function getFileName(uploadFileId) {
    const arr = uploadFileId.split('@')
    if (arr.length > 1) {
        return arr[1]
    }
    return uploadFileId
}

/**
 * 获取缩略图
 * @param fileName
 * @returns {string}
 */
export function getThumbnail(fileName) {
    if (!RuleUtil.validate(fileName, 'image') || fileName.indexOf('@') === -1) {
        // 图片
        return getViewUrl(fileName, null, 'thumbnail')
    } else {
        // 非图片
        const arr = fileName.split('@')
        const suffix = arr[1].split('.')[1]
        return HostUtil.getServerUrl() + '/thumbnail/' + suffix + '.png?uploadFileId=' + fileName
    }
}

/**
 * 获取文件的base64
 * @param uploadFileId
 * @returns {string}
 */
export function getBase64(uploadFileId) {
    return request({
        url: HostUtil.getServerUrl() + '/upload/file/view/base64',
        method: 'get',
        params: {uploadFileId}
    })
}
