import { render, staticRenderFns } from "./DictLabel.vue?vue&type=template&id=084ecfac&scoped=true"
import script from "./DictLabel.vue?vue&type=script&lang=js"
export * from "./DictLabel.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "084ecfac",
  null
  
)

export default component.exports