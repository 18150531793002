/** 数据字典label控件 @author lincl */
<template>
  <span @click="handleClick">
    <template v-if="!tag">
      {{ customLabel || label }}
    </template>
    <el-tag :type="this.cssClass" :key="type + '-' + v" size="mini" v-if="this.v && tag && label">
      {{ customLabel || label }}
    </el-tag>
  </span>
</template>

<script>
import { getCache } from '@/utils/cache'

export default {
  name: 'DictLabel',
  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      type: [String, Boolean],
      required: false,
      default: ''
    },
    tag: {
      type: [Boolean],
      required: false,
      default: true
    },
    customLabel: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      cssClass: '',
      label: '',
      v: ''
    }
  },
  created() {
    this.initData()
  },
  watch: {
    value: function(val) {
      this.initData()
    },
    type: function() {
      this.initData()
    }
  },
  methods: {
    initData() {
      if (typeof this.value === 'undefined') {
        return
      }
      // boolean类型的需要转成string
      this.v = this.value ? this.value + '' : ''
      if (this.v) {
        getCache('dict', this.type, '/sys/dict/item/select', {
          dictNo: this.type
        }).then(data => {
          this.dicts = data
          if (this.v) {
            const valueArr = this.v.split(',')
            this.label = []
            for (let i = 0; i < this.dicts.length; i++) {
              for (let j = 0; j < valueArr.length; j++) {
                if (this.dicts[i].value === valueArr[j]) {
                  this.cssClass = this.dicts[i].cssClass
                  this.label.push(this.dicts[i].label)
                  break
                }
              }
            }
            if (this.label.length > 1) {
              this.cssClass = 'primary'
            }
            this.label = this.label.join(', ')
            if (!this.label) {
              this.label = this.v
            }
          }
        })
      }
    },
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped></style>
