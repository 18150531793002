<template>
  <div>
    <el-radio-group
      :class="{ 'cl-button-radio': button }"
      v-model="selected"
      :disabled="disabled"
      @change="handleChange"
    >
      <el-radio-button v-for="item in options" :label="item.value" :key="item.value" v-if="button">
        {{ item.label }}
      </el-radio-button>
      <el-radio v-for="item in options" :label="item.value" :key="item.value" v-if="!button">
        {{ item.label }}
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
import { getCache } from '@/utils/cache'
import ArrayUtil from '../../utils/array'

export default {
  name: 'DictRadio',
  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean
    },
    // 包含选项
    includes: {
      type: String,
      required: false,
      default: ''
    },
    // 排除选项
    excludes: {
      type: String,
      required: false,
      default: ''
    },
    // 是否默认选中第一个选项
    defaultFirst: {
      type: Boolean,
      required: false,
      default: true
    },
    // 是否按钮样式
    button: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    type: function(val) {
      this.initData()
    },
    value: function(val) {
      this.initData()
    }
  },
  computed: {
    options() {
      let result = []
      if (this.includes) {
        // 包含选项
        const arr = this.includes.split(',')
        for (let i = 0; i < this.dicts.length; i++) {
          if (arr.indexOf(this.dicts[i].value) !== -1) {
            result.push(this.dicts[i])
          }
        }
      } else if (this.excludes) {
        // 排除选项
        const arr = this.excludes.split(',')
        for (let i = 0; i < this.dicts.length; i++) {
          if (arr.indexOf(this.dicts[i].value) === -1) {
            result.push(this.dicts[i])
          }
        }
      } else {
        result = this.dicts
      }
      return result
    }
  },
  data() {
    return {
      dicts: [],
      selected: ''
    }
  },
  created: function() {
    this.initData()
  },
  methods: {
    initData: function() {
      getCache('dict', this.type, '/sys/dict/item/select', {
        dictNo: this.type
      }).then(data => {
        this.dicts = data
        this.selected = ''
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].value === this.value) {
            this.selected = this.value
            break
          }
        }
        // 是否默认选中第一个选项
        if (this.defaultFirst && !this.selected && this.options.length > 0) {
          this.selected = this.options[0].value
          this.$emit('input', this.selected)
        }
      })
    },
    handleChange(event) {
      this.$emit('input', event)
      this.$emit('change', ArrayUtil.getItemByVal(this.data, this.vModel, 'value'))
    }
  }
}
</script>
