/**
 * 获取一级域名
 * @returns {string}
 */

export function getRootDomain() {
    let domain = window.location.hostname
    let hostname
    if (domain.endsWith('.com.cn')) {
        hostname = domain.match(/\.(\w+\.com\.cn)$/)
    } else if (domain.endsWith('.com')) {
        hostname = domain.match(/\.(\w+\.com)$/)
    } else {
        hostname = domain.match(/\.(\w+\.cn)$/)
    }
    if (hostname && hostname.length > 1) {
        domain = hostname[1]
    }
    console.log(domain)
    return domain
}

/**
 * 字符串转数组，去除每个项前后的空格
 * @param str
 * @param c
 * @returns {[]|*[]}
 */
export function split(str, c) {
    if (typeof c === 'undefined') {
        c = ','
    }
    if (str) {
        str = str + ''
        const arr = str.split(',')
        const result = []
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].trim()) {
                result.push(arr[i].trim())
            }
        }
        return result
    }
    return []
}

/**
 * 获取区域ID
 * @param regionId
 * @returns {null|string[]}
 */
export function getRegion(regionId) {
    if (regionId) {
        const str = regionId + ''
        const province = str.substr(0, 2) + '0000'
        const city = str.substr(0, 4) + '00'
        const area = str
        if (str.endsWith('0000')) {
            return [province, null, null]
        } else if (str.endsWith('00')) {
            return [province, city, null]
        } else {
            return [province, city, area]
        }
    }
    return null
}

/**
 * 是否手机端
 * @returns {RegExpMatchArray}
 */
export function isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}
