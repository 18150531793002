import pinyin from 'js-pinyin'

/**
 * 数组工具类
 */
const ArrayUtil = {}

/**
 * 字符串转数组，去除每个项前后的空格
 * @param str
 * @param c
 * @returns {[]|*[]}
 */
ArrayUtil.split = function(str, c) {
  if (typeof c === 'undefined') {
    c = ','
  }
  if (str) {
    str = str + ''
    const arr = str.split(c)
    const result = []
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].trim()) {
        result.push(arr[i].trim())
      }
    }
    return result
  }
  return []
}

ArrayUtil.hasItem = function(arr, value) {
  for (let i = 0; i < arr.length; i++) {
    if (value && value === arr[i]) {
      return true
    }
  }
  return false
}

/**
 * 按索引获取选项，取不到返回null
 * @param arr
 * @param index
 * @returns {null|*}
 */
ArrayUtil.getItem = function(arr, index) {
  if (arr == null) {
    return null
  }
  if (arr.length < index + 1) {
    return null
  }
  return arr[index]
}

/**
 * 获取索引
 * @param arr
 * @param item
 * @param keyName
 * @returns {number}
 */
ArrayUtil.getIndex = function(arr, item, keyName) {
  if (item == null) {
    return -1
  }
  if (keyName) {
    for (let i = 0; i < arr.length; i++) {
      if (item === arr[i][keyName] || item[keyName] === arr[i][keyName]) {
        return i
      }
    }
    return -1
  }
  return arr.indexOf(item)
}

/**
 * 按某个字段的值从数组中获得选项
 * @param arr 数组
 * @param val 值
 * @param keyName 值的key
 * @returns {null|*}
 */
ArrayUtil.getItemByVal = function(arr, val, keyName = 'id') {
  if (val == null) {
    return null
  }
  for (let i = 0; i < arr.length; i++) {
    if (val + '' === arr[i][keyName] + '') {
      return arr[i]
    }
  }
  return null
}

/**
 * 按某个字段的值从数组中获得选项
 * @param arr 数组
 * @param val 值
 * @param keyName 值的key
 * @returns {null|*}
 */
ArrayUtil.getItemsByVal = function(arr, val, keyName = 'id') {
  if (val == null) {
    return null
  }
  const result = []
  for (let i = 0; i < arr.length; i++) {
    if (val + '' === arr[i][keyName] + '') {
      result.push(arr[i])
    }
  }
  return result
}

/**
 * 按某个字段的多个值从数组中获得选项
 * @param arr 数组
 * @param vals 值
 * @param keyName 值的key
 * @returns {null|*}
 */
ArrayUtil.getItemsByVals = function(arr, vals, keyName = 'id') {
  if (vals === null) {
    return []
  }
  if (typeof vals === 'string') {
    vals = this.split(vals)
  }
  const items = []
  for (let i = 0; i < vals.length; i++) {
    const item = this.getItemsByVal(arr, vals[i], keyName)
    for (let j = 0; j < item.length; j++) {
      items.push(item[j])
    }
  }
  return items
}

/**
 * 添加一项
 * @param arr
 * @param item
 * @param keyName
 * @returns {*}
 */
ArrayUtil.addItem = function(arr, item, keyName) {
  if (!arr) {
    return arr
  }
  if (item !== 0 && !item) {
    return arr
  }
  if (keyName) {
    // 对象
    if (this.getItemByVal(arr, item[keyName], keyName) == null) {
      arr.push(item)
    }
  } else if (arr.indexOf(item) === -1) {
    // 数值
    arr.push(item)
  }
  return arr
}

/**
 * 添加集合
 * @param arr
 * @param items
 * @param keyName
 * @returns {*}
 */
ArrayUtil.addItems = function(arr, items, keyName) {
  if (!arr) {
    return arr
  }
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    if (keyName) {
      // 对象
      if (this.getItemByVal(arr, item[keyName], keyName) == null) {
        arr.push(item)
      }
    } else if (arr.indexOf(item) === -1) {
      // 数值
      arr.push(item)
    }
  }
  return arr
}

/**
 * 删除数组的某个项
 * @param arr
 * @param item
 * @returns {*}
 */
ArrayUtil.removeItem = function(arr, item, keyName) {
  if (!arr) {
    return arr
  }
  if (item !== 0 && !item) {
    return arr
  }
  if (keyName) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][keyName] === item[keyName]) {
        arr.splice(i, 1)
        break
      }
    }
  } else {
    const index = arr.indexOf(item)
    arr.splice(index, 1)
  }
  return arr
}

/**
 * 删除数组的某个项
 * @param arr
 * @param items
 * @param keyName
 * @returns {*}
 */
ArrayUtil.removeItems = function(arr, items, keyName) {
  if (items === null) {
    return []
  }
  if (typeof items === 'string') {
    items = this.split(items)
  }
  for (let i = 0; i < items.length; i++) {
    ArrayUtil.removeItem(arr, items[i], keyName)
  }
  return arr
}

/**
 * 前端搜索，支持拼音搜索
 * @param data 数据
 * @param val 值
 * @param keyName 值的key
 * @returns {[]|any}
 */
ArrayUtil.search = function(data, val, keyName = 'id') {
  if (!val || val.trim() === '') {
    return Object.assign([], data)
  }
  val = val.trim()
  const result = []
  for (let i = 0; i < data.length; i++) {
    const label = data[i][keyName]
    if (label.indexOf(val) !== -1) {
      result.push(data[i])
      continue
    }
    const py = pinyin.getCamelChars(label).toLowerCase()
    if (py.indexOf(val.toLowerCase()) !== -1) {
      result.push(data[i])
      continue
    }
  }
  return result
}

/**
 *数组去重
 * @param array
 * @returns {*}
 */
ArrayUtil.distinct = function(array) {
  const seen = new Set()
  return array.filter(function(element) {
    if (seen.has(element)) {
      return false
    }
    seen.add(element)
    return true
  })
}

/**
 *数组对象，按值去重
 * @param array
 * @returns {*}
 */
ArrayUtil.distinctObj = function(array, keyName) {
  const seen = new Set()
  return array.filter(function(element) {
    if (seen.has(element[keyName])) {
      return false
    }
    seen.add(element[keyName])
    return true
  })
}

/**
 * 获取非空集合
 * @param array
 * @param defaultValue 默认值，不传，则剔除该项
 * @returns {[]}
 */
ArrayUtil.getNotNull = function(array, defaultValue) {
  const result = []
  for (let i = 0; i < array.length; i++) {
    if (array[i] == null) {
      array[i] = defaultValue
    }
    if (array[i] !== null) {
      result.push(array[i])
    }
  }
  return result
}

/**
 * 设置某项的属性为Null
 * @param item
 */
ArrayUtil.setItemNull = function(item) {
  let key
  for (key in item) {
    if (item[key] instanceof Array) {
      if (item[key] && item[key].length > 0) {
        item[key] = [ArrayUtil.setItemNull(item[key][0])]
      } else {
        item[key] = []
      }
    } else if (item[key] instanceof Object) {
      item[key] = ArrayUtil.setItemNull(item[key])
    } else {
      item[key] = null
    }
  }
  return item
}

/**
 * 拼接
 * @param array
 * @param separator
 * @returns {string}
 */
ArrayUtil.join = function(array, separator) {
  const result = []
  array.forEach(item => {
    if (item) {
      result.push(item)
    }
  })
  return result.join(separator)
}

export default ArrayUtil
