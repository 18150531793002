/**
 * 请求发送实例
 */
import axios from 'axios'
import {Message} from 'element-ui'
import {getToken} from '@/utils/token'
import Config from '@/config'
import HostUtil from "@/utils/host";

// 创建axios实例
const service = axios.create({
    baseURL: HostUtil.getServerUrl(), // api 的 base_url
    timeout: Config.TIMEOUT // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
    config => {
        // 让每个请求携带自定义token 请根据实际情况自行修改
        const token = getToken()
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token
        }
        config.headers['Content-Type'] = 'application/json'
        // 添加客户端标识
        config.headers['clientId'] = Config.CLIENT_ID
        config.headers['clientApp'] = 'pc'
        return config
    },
    error => {
        // Do something with request error
        console.log(error) // for debug
        Promise.reject(error)
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        const status = response.status
        // 请求成功
        if (status === 200) {
            const data = response.data
            if (
                data.success ||
                response.config.url === '/auth/login' ||
                response.config.url === '/sms/code/send'
            ) {
                // 操作成功
                return data
            } else if (data.code === 'timeout') {
                // 登录超时
                Message.error('登录超时或被踢出，请重新登录！')
            } else if (data.message) {
                if (data.message === 'Bad credentials！') {
                    Message.error('用户名或密码错误！')
                    return Promise.reject('error')
                }
                // 操作失败
                Message.error(data.message)
                return Promise.reject(data)
            } else {
                // 未知错误
                console.log('API【' + response.config.url + '】操作失败！错误：未知错误！')
                Message.error('未知错误！')
                return Promise.reject('error')
            }
        } else if (status < 200 || status > 300) {
            console.log(response)
            Message.error(response.message)
            return Promise.reject('error')
        } else {
            return response.data
        }
    },
    error => {
        let status = 0
        try {
            status = error.response.status
        } catch (e) {
            if (error.toString().indexOf('Error: timeout') !== -1) {
                // Message.error('登录超时或被踢出，请重新登录！')
                return Promise.reject(error)
            }
            if (error.toString().indexOf('Error: Network Error') !== -1) {
                // Message.error('网络错误或登录超时或被踢出，请重新登录！')
                return Promise.reject(error)
            }
        }
        if (status === 401) {
            Message.error('登录超时或被踢出，请重新登录！')
        } else if (status === 403) {
            unAuth(error.response.data.message)
        } else if (status === 404) {
            Message.error('API【' + error.response.config.url + '】不存在或无法提供服务！')
        } else {
            const errorMsg = error.response.data.message
            if (errorMsg !== undefined && errorMsg) {
                Message.error(errorMsg)
            } else {
                console.log(error.response)
            }
        }
        return Promise.reject(error)
    }
)

export default service
