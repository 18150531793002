<template>
  <img
    :src="defaultSrc"
    v-real-src="realSrc"
    :class="cssClass"
    :style="cssStyle"
    :height="height"
    :width="width"
  />
</template>

<script>
import img404 from '../../assets/system/img404.png'
import logo from '../../assets/logo.png'
import avatar from '../../assets/system/avatar.png'
import { getViewUrl } from '../../api/base/uploadFile'
import el from "element-ui/src/locale/lang/el";

export default {
  name: 'ImageLabel',
  props: {
    // 地址
    src: {
      type: String,
      required: false,
      default: ''
    },
    // 默认地址
    defaultImg: {
      type: String,
      required: false,
      default: null
    },
    // class样式
    cssClass: {
      type: String,
      required: false,
      default: ''
    },
    // style样式
    cssStyle: {
      type: Object,
      required: false,
      default: null
    },
    width: {
      type: [String, Number],
      default: '100'
    },
    height: {
      type: [String, Number],
      default: '100'
    }
  },
  data() {
    return {}
  },
  computed: {
    defaultSrc() {
      if (this.defaultImg === 'avatar') {
        return avatar
      } else if (this.defaultImg === 'logo') {
        return logo
      }
      return img404
    },
    realSrc() {
      if (!this.src) {
        return this.defaultSrc
      }
      if (!this.src.startsWith('http')) {
        return getViewUrl(this.src, this.defaultImg)
      }
      return this.src
    }
  },
  methods: {},
  watch: {
    defaultSrc: {
      immediate: true,
      deep: true,
      handler(n, o) {
        // console.log('defaultSrc', n, o)
      }
    }
  }
}
</script>

<style scoped></style>
