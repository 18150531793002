<template>
  <span>{{ label }}</span>
</template>

<script>
import DateUtil from '../../utils/date'

export default {
  name: 'DateLabel',
  props: {
    value: {
      type: [Number, String],
      required: false,
      default: ''
    },
    // 格式化
    format: {
      type: String,
      required: false,
      default: 'yyyy-MM-dd'
    }
  },
  data() {
    return {
      label: '',
      newValue: this.value
    }
  },
  created() {
    this.initLabel()
  },
  watch: {
    value: function() {
      this.initLabel()
    }
  },
  methods: {
    initLabel() {
      this.label = DateUtil.format(this.value, this.format)
    }
  }
}
</script>

<style scoped></style>
